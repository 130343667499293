import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import BookingPage from './pages/BookingPage';
import TimeSlots from './pages/TimeSlots';
import Confirm from './pages/Confirm';

import { RequireAuth, RequireAdmin } from './components/RequireAuth';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { createBrowserHistory } from "history";

import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';

import SignInPage from './pages/signin';

import '@aws-amplify/ui-react/styles.css';

I18n.putVocabularies(translations);
I18n.setLanguage('pl');

const myTheme = {
  name: 'my-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: '#465D56' },
          20: { value: '#465D56' },
          30: { value: '#465D56' },
          40: { value: '#465D56' },
          50: { value: '#465D56' },
          60: { value: '#465D56' },
          70: { value: '#465D56' },
          80: { value: '#465D56' },
          90: { value: '#4E4D4A' },
          100: { value: '#465D56' },
        }
      },
      background: {
        primary: { value: '#DCD5C9' },
        secondary: { value: '#A1AC9E' },
      },
      border: {
        primary: { value: '#465D56' },
      },
      font: {
        primary: { value: '#4E4D4A' },
        secondary: { value: '#4E4D4A' },
      },
    },
  }
};

export const appHistory = createBrowserHistory();

function App() {
  
  return (
    <>
    <AmplifyProvider theme={myTheme} >
    <Authenticator.Provider>
    <Router history={appHistory}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
    </Authenticator.Provider>
    </AmplifyProvider>
    </>
  );
}

export default App;
