import styled from "styled-components";

import leftColumnImage from '../../images/bgj2.png';
import leftColumnHoverImage from '../../images/bgj1.png';
import rightColumnImage from '../../images/bjc2.png';
import rightColumnHoverImage from '../../images/bjc1.png';

import leftLogoImageHover from '../../images/greyleft.png';
import rightLogoImageHover from '../../images/greyright.png';


export const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100vh;
    input {
        width: 100%;
    }
         background: linear-gradient(
         108deg,
         rgba(220, 213, 201, 1) 0%,
         rgba(190, 179, 160, 1) 100%
    );
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`;

export const Linker = styled.a`
  display: flex;
  width: 100%;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
  cursor: pointer;
  transform: rotate(0deg);

  &:hover {
    background-image: var(--hover-background-image);
    transform: rotate(0deg);
  }
`;

export const Image1 = styled.img`
    width: 55%;
    position: absolute;
    right: 0;
    ${Column}:hover & {
        content: url(${leftLogoImageHover});
      }
`;

export const Image2 = styled.img`
    width: 55%;
    position: absolute;
    left: 0;
    ${Column}:hover & {
        content: url(${rightLogoImageHover});
      }
`;

export const LeftColumn = styled(Column)`
  --background-image: url(${leftColumnImage});
  --hover-background-image: url(${leftColumnHoverImage});
`;

export const RightColumn = styled(Column)`
  --background-image: url(${rightColumnImage});
  --hover-background-image: url(${rightColumnHoverImage});
`;

// export const HeroContainer = styled.div`
//     // background: #0c0c0c;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 0 10px;
//     height: 900 px;
//     position: relative;
//     z-index: 1;
    
//     :before {
//         content: '';
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%),
//         linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
//         z-index: 2;
//     }
//     // min-height: 100%;
//     // // position: fixed;
//     // // bottom: 0;
//     // // left: 0;
//     // // right: 0;
//     // top: 0;
//     // // z-index: 0;
//     // //overflow: hidden;
//     // background: linear-gradient(
//     //     108deg,
//     //     rgba(220, 213, 201, 1) 0%,
//     //     rgba(190, 179, 160, 1) 100%
//     // );
// `;

// export const HeroBg = styled.div`
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
// `;

// export const VideoBg = styled.img`
//     width: 100%;
//     height: 100%;
//     -o-object-fit: cover;
//     object-fit: cover;
//     background: #232a34;
// `;

// export const HeroContent = styled.div`
//     z-index: 3;
//     max-width: 1200px;
//     min-width: 50%;
//     position: absolute;
//     padding: 8px 20px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     @media screen and (max-width: 768px) {
//         min-width: 100%;
//         padding: 0;
//     }
// `;

// export const HeroH1 = styled.h1`
//     color: #465D56;
//     font-size: 56px;
//     text-align: center;
//     font-family: 'HelloParisSansExtraLight';
    
//     @media screen and (max-width: 768px) {
//         font-size: 48px;
//     }
    
//     @media screen and (max-width: 480px) {
//         font-size: 40px;
//     }
// `;

// export const HeroP = styled.p`
//     margin-top: 24px;
//     color: #465D56;
//     font-size: 32px;
//     text-align: center;
//     max-width: 700px;

//     @media screen and (max-width: 768px) {
//         font-size: 30px;
//     }
    
//     @media screen and (max-width: 480px) {
//         font-size: 20px;
//     }
// `;

// export const HeroBtnWrapper = styled.div`
//     margin-top: 16px;
//     display: flex;
//     align-items: center;
//     width: 90%;
//     justify-content: space-between;

//     @media screen and (max-width: 768px) {
//         flex-direction: column;
//     }
// `;

// export const Logo = styled.img`
//     height: 400px;
//     margin-top: -80px;

//     @media screen and (max-width: 768px) {
//         height: 300px;
//     }
// `;

// export const Column = styled.div`
//     flex: 1;
//     max-width: 50%;
//     flex-basis: 50%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 15px;

//     @media screen and (max-width: 768px) {
//         max-width: 100%;
//         flex-basis: 100%;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         padding: 15px;
//     }
// `;

// export const Linka = styled.a`
//     color: #4E4D4A;
//     font-size: 32px;
//     text-align: center;
//     max-width: 600px;
//     text-decoration: none;

//     &:hover{
//         color: #fff;
//     }
    
//     @media screen and (max-width: 768px) {
//         font-size: 30px;
//     }
    
//     @media screen and (max-width: 480px) {
//         font-size: 28px;
//     }
// `;




