import styled from "styled-components";
import {FaTimes} from "react-icons/fa";

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    max-width: 380px;
    height: 100%;
    background: #465D56;
    display: grid;
    align-items: center;
    top: 0;
    right: 0;
    transition: 1s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    right: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`;

export const CloseIcon = styled(FaTimes)`
    color: #BEB3A0;
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

export const SidebarWrapper = styled.div`
    color: #BEB3A0;
`;

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;
    
    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`;

export const SidebarLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    text-decoration: none;
    list-style: none;
    transition: 0.4s ease-in-out;
    text-decoration: none;
    color: #BEB3A0;
    cursor: pointer;
    font-family: 'HelloParisSansExtraLight';

    &:hover {
        color: #fff;
        transition: 0.4s ease-in-out;
    }
`;

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`;

export const SidebarRoute = styled.a`
    border-radius: 50px;
    background: #BEB3A0;
    white-space: nowrap;
    padding: 16px 64px;
    color: #465D56;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #A1AC9E;
        color: #4E4D4A;
    }
`;

