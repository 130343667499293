import React, {useState} from 'react'
import { HeroContainer, LeftColumn, RightColumn, Container, Linker, Image1, Image2 } from './HeroElements'

import leftLogoImageNormal from '../../images/greenleft.png';
import rightLogoImageNormal from '../../images/greenright.png';


const HeroSection = () => {

  return (
    <>
        <HeroContainer id='home'>
        <Container>
          <Linker href="https://sklep.atenida.pl">
            <LeftColumn
            ><Image1 src = {leftLogoImageNormal}/>
            </LeftColumn>
          </Linker>
          <Linker href="https://coaching.atenida.pl">
            <RightColumn
            ><Image2 src = {rightLogoImageNormal}/>
            </RightColumn>
          </Linker>
        </Container>
        </HeroContainer>
    </>
  )
}

export default HeroSection