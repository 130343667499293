import React, { useState } from 'react'
import HeroSection from '../components/Hero'


const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <HeroSection />
        </>
    )
}

export default Home