import styled from "styled-components";  
import { Link } from "react-router-dom";

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({ primary }) => (primary ? "#BEB3A0" : "#465D56")};
    white-space: nowrap;
    padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
    color: ${({ dark }) => (dark ? "#DCD5C9" : "#fff")};
    font-size: ${({ fontBig }) => (fontBig ? "38px" : "24px")};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.75s ease-in-out;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        transition: all 1s ease-in-out;
        color: ${({ dark }) => (dark ? "#DCD5C9" : "#4E4D4A")};
        background: ${({ primary }) => (primary ? "#A1AC9E" : "#BEB3A0")};
    }
`;

export const ButtonOut = styled.a`
    border-radius: 50px;
    background: ${({ primary }) => (primary ? "#BEB3A0" : "#465D56")};
    white-space: nowrap;
    padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
    color: ${({ dark }) => (dark ? "#4E4D4A" : "#BEB3A0")};
    font-size: ${({ fontBig }) => (fontBig ? "38px" : "24px")};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.75s ease-in-out;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        transition: all 1s ease-in-out;
        background: ${({ primary }) => (primary ? "#A1AC9E" : "#A1AC9E")};
    }
`;

